<template>
  <div class="wrapper">
    <tree-to-vliew :isDeep="false"></tree-to-vliew>
    <create-template></create-template>
  </div>

</template>

<script>
import { isPhoneNum } from "@/utils/validate";
import UploadImg from "@/components/UploadImg";
import { addInformation } from "@/api";
import CreateTemplate from "../commonViews/CreateTemplate";
import TreeToVliew from "../commonViews/TreeToVliew";
import RightContent from '../commonViews/RightContent'
export default {
  name: "supervision",
  components: {
    RightContent,
    TreeToVliew,
    CreateTemplate,
    UploadImg,
  },
  methods: {
    filterData() {
      let data = {};
      Object.keys(this.form).forEach((item) => {
        data[item] = this.form[item];
      });
      return data;
    },
    async submit() {
      try {
        if (!isPhoneNum(this.form.phone_number)) {
          throw new Error("手机格式错误");
        }
        const requestData = {
          type: 1,
          value: this.filterData(),
        };
        console.log(requestData);
        const res = await addInformation(requestData);
        if (res.status == 200) {
          this.$success("添加成功");
          this.$router.push({
            path: "/company",
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$error(e.message);
        }
      }
    },
  },
  data() {
    return {
      form: {
        img: "",
        name: "",
        // found_time: "",
        people_name: "",
        phone_number: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #f8f8f8;
  display: flex;
  height: 100%;
}
</style>

<template>
  <right-content>
    <div class="content">
      <div ref="tabs" class="tabs">
        <div id="one" class="active">批量创建</div>
        <div id="two">单个创建</div>
      </div>
      <div class="tab-content">
        <div class="page-content" v-if="flag">
          <upload-mulit></upload-mulit>
        </div>
        <div v-else>
          <edit-info
            :editInfoList="editInfoList"
            @submitfn="submitForm"
          ></edit-info>
        </div>
      </div>
      <div class="bottom" v-if="flag">
        <h2>导入说明</h2>
        <span>1、导入步骤为：①下载并填写模板。②上传Excel文件。③完成。</span>
        <span>2、请按照导入表格说明来填写对应的信息，必填信息缺失会使导入失败。</span>
      </div>
    </div>
  </right-content>
</template>

<script>
import UploadMulit from "./UploadMulit";
import SingeUpload from "./SingeUpload";
import RightContent from "@/views/commonViews/RightContent";
import EditInfo from "@/views/BaseInfo/editInfo";
import { isPhoneNum } from "@/utils/validate";

import {addInformation} from '@/api';

export default {
  components: { SingeUpload, UploadMulit, RightContent, EditInfo },
  mounted() {
    const tabs = this.$refs.tabs;
    tabs.childNodes.forEach((node) => {
      node.addEventListener("click", () => {
        //清除样式
        tabs.childNodes.forEach((node) => {
          node.classList.remove("active");
        });
        node.classList.add("active");
        if (node.id == "one") {
          this.flag = true;
        } else {
          this.flag = false;
        }
      });
    });
  },
  data() {
    return {
      editInfoList: [
        { name: "总包部名称", field: "name" },
        { name: "总包部管理员", field: "people_name" },
        { name: "联系电话", field: "phone_number" },
        {name:"",field:"",disable:true}
      ],
      activities: ["下载并填写模板", "上传Excel模板", "完成"],
      flag: true,
    };
  },
  methods: {
    async submitForm(form) {
      try {
        if (!isPhoneNum(form.phone_number)) {
          throw new Error("手机格式错误");
        }
        const requestData = {
          type: 1,
          value: form,
        };
        const res = await addInformation(requestData);
        if (res.status == 200) {
          this.$success("添加成功");
          this.$router.push({
            path:"/company/manager-company"
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$message(e.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    div {
      margin-right: 30px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      box-sizing: border-box;
      &.active {
        border-bottom: 3px solid red;
      }
    }
  }
  .tab-content {
    flex: 1;
    margin: 30px auto;
  }
  .bottom {
    display: flex;
    flex-direction: column;
    line-height: 25px;
    margin: 20px;
  }
}
.el-input__inner {
  border: none !important;
}
.el-input__inner:focus {
  border: none;
}
::v-deep .el-select {
  width: 120px;
}
</style>
